import { Box, Grid } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Chart from '../chart/Chart';
import Leaderboard from '../components/leaderboard/Leaderboard';
import Modal from '../components/modal/Modal';
import Header from '../header/Header';
import { useSocketListeners } from '../utils/socket';
import { ErrorType, getCookie, ModalContentType } from '../utils/utils';
import { useSnackbar } from 'notistack';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import axios from 'axios';
import GameStatus from '../components/gameStatus/GameStatus';
import CustomSnackbar from '../components/customSnackbar/CustomSnackbar';
import ErrorSnackbar from '../components/errorSnackbar/ErrorSnackbar';

const Game = (props: { blackSpotAddress: string; doubloonAddress: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pirates, setPirates] = useState<Pirate[]>([]);
  const [customSnackbarOpen, setCustomSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarCause, setErrorSnackbarCause] = useState<ErrorType>(
    ErrorType.UnknownError
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ModalContentType>(
    ModalContentType.ReplenishBST
  );
  const [winners, setWinners] = useState<Winner[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [recepientAddress, setRecepientAddress] = useState('');
  const [gameResultsData, setGameResultsData] = useState<GameResultData[]>([]);
  const [currentPage, setCurrentPage] = useState('game');
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [finishTransactions, setFinishTransactions] = useState<number>(-1);

  const handleTransactionSnackbar = (
    sender: string,
    receiver: string,
    amount: string
  ) => {
    enqueueSnackbar(
      `The chest ${receiver} was replenished with ${amount} tokens from a chest ${sender}`,
      {
        autoHideDuration: 3000,
        variant: 'info',
      }
    );
  };

  const handleOpenCustomSnackbar = () => {
    if (
      !Boolean(getCookie('bsTokenAdded')) ||
      !Boolean(getCookie('dTokenAdded'))
    ) {
      setCustomSnackbarOpen(true);
    }
  };

  const handleOpenErrorSnackbar = (errorType: ErrorType) => {
    setErrorSnackbarCause(errorType);
    setErrorSnackbarOpen(true);
  };

  const handleCloseCustomSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCustomSnackbarOpen(false);
  };

  const handleAnywayCloseCustomSnackbar = () => {
    setCustomSnackbarOpen(false);
  };

  const handleCloseErrorSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  const handleGoToGame = () => {
    setCurrentPage('game');
  };

  const handleGoToLeaderboard = () => {
    setCurrentPage('leaderboard');
  };

  const handleClearClickRecipientAddress = () => {
    setRecepientAddress('');
  };

  const handleOpenModal = (contentType: ModalContentType) => {
    setModalOpen(true);
    setModalContent(contentType);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const { onGameTransaction, onInitialGameData, onGameOver, onWinner } =
    useSocketListeners();

  //handleOpenModal(ModalContentType.GameOver)

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      handleOpenCustomSnackbar();
    }
  }, []);

  useEffect(() => {
    axios.get('https://dev.pirate.top/api/results').then(
      (response: any) => {
        console.log('get games results');
        setGameResultsData(response.data);
      },
      (error: any) => {
        console.log('no game results');
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    onGameOver((gameOverData: GameOverData) => {
      handleOpenModal(ModalContentType.GameOver);
    });
  }, [onGameOver]);

  useEffect(() => {
    onWinner((winnerData: Winner) => {
      console.log(winnerData);

      const winner: Winner = {
        winner: winnerData.winner,
        prize: BigNumber.from(winnerData.prize).toNumber(),
        gameStartTime: winnerData.gameStartTime,
      };
      setWinners([...winners, winner]);
    });
  }, []);

  useEffect(() => {
    onGameTransaction((transactionData: GameTransaction) => {
      console.log('token transfer detected\n________________________');
      console.log(transactionData);
      console.log(pirates);
      console.log('\n________________________');

      const sender = transactionData.sender;
      const receiver = transactionData.receiver;
      const newPirates = pirates.map((wallet, walletId) => {
        console.log('> ', walletId, { wallet });

        if (wallet.address === receiver.address) {
          return {
            ...wallet,
            address: receiver.address,
            amount: BigNumber.from(receiver.amount).toString(),
            isInGame: receiver.isInGame,
            hasTransfered: receiver.hasTransfered,
          };
        }
        if (wallet.address === sender.address) {
          return {
            ...wallet,
            address: sender.address,
            amount: BigNumber.from(sender.amount).toString(),
            isInGame: sender.isInGame,
            hasTransfered: sender.hasTransfered,
          };
        }
        return wallet;
      });

      setTotalTransactions(
        BigNumber.from(transactionData.totalTransactions).toNumber()
      );
      setFinishTransactions(
        BigNumber.from(transactionData.finishTransactions).toNumber()
      );
      setPirates(newPirates);
      handleTransactionSnackbar(
        sender.address,
        receiver.address,
        BigNumber.from(sender.amount).toString()
      );
    });
  }, [onGameTransaction]);

  useEffect(() => {
    onInitialGameData((initialGameData) => {
      console.log('newGame:', { initialGameData });

      const pirates: Pirate[] = [];
      const startTime = new Date(
        BigNumber.from(initialGameData.startGame).toNumber() * 1000
      );
      setStartDate(startTime);
      console.log('lol');
      try {
        initialGameData.gamers.forEach((pirate: any) => {
          pirates.push({
            ...pirate,
            amount: BigNumber.from(pirate.amount).toString(),
          });
        });
      } catch (e) {
        handleOpenErrorSnackbar(ErrorType.UnknownError);
        console.error(e);
      }
      setPirates(pirates);
      console.log('initialGameData.finishTransactions');
      console.log(initialGameData.finishTransactions);
      console.log(
        'BigNumber.from(initialGameData.totalTransactions).toNumber() is'
      );
      console.log(BigNumber.from(initialGameData.totalTransactions).toNumber());
      setTotalTransactions(
        BigNumber.from(initialGameData.totalTransactions).toNumber()
      );
      setFinishTransactions(
        BigNumber.from(initialGameData.finishTransactions).toNumber()
      );
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Header
                  startDate={startDate}
                  recepientAddress={recepientAddress}
                  setRecepientAddress={setRecepientAddress}
                  handleOpenModal={handleOpenModal}
                  handleClearClickRecipientAddress={
                    handleClearClickRecipientAddress
                  }
                  currentPage={currentPage}
                  handleGoToGame={handleGoToGame}
                  handleGoToLeaderboard={handleGoToLeaderboard}
                />
                <Box
                  sx={{
                    flexGrow: 0,
                    flexShrink: 1,
                    flexBasis: 'auto',
                  }}
                ></Box>
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Chart
                    pirates={pirates}
                    recepientAddress={recepientAddress}
                    setRecepientAddress={setRecepientAddress}
                    handleOpenModal={handleOpenModal}
                    setPirates={setPirates}
                  >
                    {currentPage === 'game' ? (
                      <GameStatus
                        startDate={startDate}
                        totalTransactions={totalTransactions}
                        finishTransactions={finishTransactions}
                      />
                    ) : null}
                  </Chart>
                </Box>
                <Modal
                  isModalOpen={isModalOpen}
                  modalContent={modalContent}
                  handleCloseModal={handleCloseModal}
                  recepientAddress={recepientAddress}
                  setRecepientAddress={setRecepientAddress}
                  handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                  winners={winners}
                />
                <CustomSnackbar
                  isOpen={customSnackbarOpen}
                  handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                  handleClose={handleCloseCustomSnackbar}
                  handleAnywayClose={handleAnywayCloseCustomSnackbar}
                  bsAddress={props.blackSpotAddress}
                  dAddress={props.doubloonAddress}
                />
                <ErrorSnackbar
                  isOpen={errorSnackbarOpen}
                  handleClose={handleCloseErrorSnackbar}
                  errorCause={errorSnackbarCause}
                />
              </Box>
            </>
          }
        />
        <Route
          path='/leaderboard'
          element={
            <div style={{ height: '100%' }}>
              <Header
                startDate={startDate}
                recepientAddress={recepientAddress}
                setRecepientAddress={setRecepientAddress}
                handleOpenModal={handleOpenModal}
                handleClearClickRecipientAddress={
                  handleClearClickRecipientAddress
                }
                currentPage={currentPage}
                handleGoToGame={handleGoToGame}
                handleGoToLeaderboard={handleGoToLeaderboard}
              />

              <Leaderboard
                gameResultData={gameResultsData}
                avaliableDaysPeriods={[7, 30, 0]}
              />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Game;

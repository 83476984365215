import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonCustom } from '../buttonCustom/ButtonCustom';
import blackSpotTokenImage from "../../assets/images/BlackSpotToken.jpeg";
import { useSnackbar } from 'notistack';
import { Alert } from '@mui/material';
import { errorSnackbarSelectText, ErrorType } from '../../utils/utils';

export interface SnackbarMessage {
  message: string;
  key: number;
}

export interface State {
  open: boolean;
  snackPack: readonly SnackbarMessage[];
}


interface Props {
  isOpen: boolean;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  errorCause: ErrorType;
};

const ErrorSnackbar: React.FC<Props> = ({ isOpen, handleClose, errorCause }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev: readonly SnackbarMessage[]) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);



  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        autoHideDuration={30000}
        onClose={handleClose}
        action={
          <Alert severity="error">
            {errorSnackbarSelectText(errorCause)} <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          </Alert>
        }
      />

    </div>
  );
}


export default ErrorSnackbar;
import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

const ButtonCustom = styled(Button)`
  border-radius: 25px;
`;

export const PeriodButtons: React.FC<LeaderboardNS.PeriodButtons> = ({
  selectedPeriod,
  handleChangePeriod,
  avaliableDaysPeriods,
  sortingType,
  handleChangeSortingType,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ marginBottom: 2, maxWidth: "100%" }}
    >
      {avaliableDaysPeriods.map((period, index) => (
        <ButtonCustom
          onClick={handleChangePeriod}
          key={index}
          data-id={String(period)}
          variant="contained"
          fullWidth
          sx={
            Number(selectedPeriod) === period
              ? {
                  color: "white",
                  backgroundColor: "#E99A01",
                  boxShadow: "none",
                }
              : {
                  color: "#202020",
                  backgroundColor: "#D9D9D9",
                  boxShadow: "none",
                }
          }
        >
          {period !== 0 ? `${period} Days` : `All-Time`}
        </ButtonCustom>
      ))}
      <FormControl fullWidth>
        <InputLabel id="select-sorting-label">Sorting</InputLabel>
        <Select
          labelId="select-sorting-label"
          id="select-sorting"
          value={sortingType}
          onChange={handleChangeSortingType}
          sx={{
            borderRadius: 25,
            backgroundColor: "#D9D9D9",
            color: "#202020",
          }}
        >
          <MenuItem value={0}>By game date: latest first</MenuItem>
          <MenuItem value={1}>By game date: oldest first</MenuItem>
          <MenuItem value={2}>By winning amount: descending</MenuItem>
          <MenuItem value={3}>By winning amount: ascending</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

import {
  Box,
  Button,
  Link,
  Modal as MuiModal,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { BigNumber, ethers } from "ethers";
import { useState } from "react";
import { useChainMethods, useChainValues } from "../../utils/chain";
import { ErrorType, ModalContentType } from "../../utils/utils";

import { ChainLogo } from "../chainLogo/ChainLogo";

const TextFieldCustom = styled(TextField)`
  fieldset {
    border-radius: 25px;
  }
`;

const ButtonCustom = styled(Button)`
  border-radius: 25px;
`;

const GameOver: React.FC<ModalNS.GameOver> = ({ winners }) => {
  const { address } = useChainValues();

  const playerIndex = winners
    .map((winner) => winner.winner)
    .indexOf(address ?? "");
  console.log(
    "______________\n\n\n\n\n",
    { winners },
    "\n\n\n\n\n______________"
  );

  const listItems = winners.map((winner, index) => (
    <Stack direction="column" sx={{ marginBottom: 1 }}>
      <Typography
        id="modal-modal-title"
        component="h2"
        sx={{ marginBottom: 1 , color: '#662113', fontSize: 18 }}
      >
        {(winners.length > 1 ? `winner #${index} `: '')   + winner.winner}
      </Typography>
      <Typography
        id="modal-modal-title"
        component="h2"
        sx={{ marginBottom: 1 }}
      >
        {ethers.utils.formatEther(winner.prize)}
        <ChainLogo />
      </Typography>
    </Stack>
  ));

  return (
    <Stack direction="column">
      <div>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ margin: 2, marginBottom: 4 , color: '#555555', fontSize: 30}}
        >
          {playerIndex === -1 ? `Game Over!` : `You won!`}
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ margin: 2, marginBottom: 4 }}
        >
          {winners.length > 1 ? 'List of winners:': 'Winner:'}
        </Typography>
      </div>
      <Stack direction="column" spacing={2} sx ={{ maxHeight: 600, overflowY: 'auto',  margin: 2}}>
        {listItems}
      </Stack>
    </Stack>
  );
};

const TransferToAnotherComponent: React.FC<
  ModalNS.TransferToAnotherComponentProps
> = ({
  amount,
  onChangeAmount,
  recepientAddress,
  transfer,
  handleOpenErrorSnackbar,
  setRecepientAddress,
  handleCloseModal,
  setAmount,
}) => {
  const onChangeRecepientAddress = (event: any) => {
    event.preventDefault();
    setRecepientAddress(event.target.value);
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ margin: 2, marginBottom: 4 }}
      >
        Transfer to another wallet
      </Typography>
      <Stack direction="column" spacing={2}>
        <TextFieldCustom
          label="Recipient"
          value={recepientAddress}
          onChange={onChangeRecepientAddress}
        />
        <TextFieldCustom
          label="BST amount"
          value={amount}
          onChange={onChangeAmount}
          InputProps={{
            inputProps: {
              min: 0,
              type: "string",
            },
          }}
        />
        <ButtonCustom
          disabled={!ethers.utils.isAddress(recepientAddress)}
          onClick={(() => {
            try {
              return BigNumber.from(amount).gte(0)
                ? () => {
                    try {
                      transfer(recepientAddress, String(amount));
                    } catch (e) {
                      console.error(e);
                      handleOpenErrorSnackbar(ErrorType.ErrorTransaction);
                    }
                    handleCloseModal();
                    setAmount(0);
                  }
                : () =>
                    console.error(
                      "trasfer tokens amount is null or signer is undefined"
                    );
            } catch (e) {
              return () => console.error("Wrong amount", { e });
            }
          })()}
          variant="contained"
          sx={{ padding: 2 }}
        >
          {" "}
          Transfer tokens{" "}
        </ButtonCustom>
      </Stack>
    </>
  );
};

const ChestReplenishComponent: React.FC<
  ModalNS.ChestReplenishComponentProps
> = ({
  amount,
  onChangeAmount,
  handleCloseModal,
  setAmount,
  handleOpenErrorSnackbar,
}) => {
  const { deposit } = useChainValues();
  const { depositETH, withdraw } = useChainMethods();
  const [depositOrWithdraw, setDepositOrWithdraw] = useState(0);
  const handleVectorChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setDepositOrWithdraw(newValue);
  };
  const handleAction = () => {
    if (!amount) return console.error("trasfer tokens amount is null");
    return depositOrWithdraw === 0
      ? (() => {
          try {
            depositETH(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.ReplenishingError);
            console.error(e);
          }
          handleCloseModal();
          setAmount(0);
        })()
      : (() => {
          try {
            withdraw(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.WithdrawingError);
            console.error(e);
          }
          handleCloseModal();
          setAmount(0);
        })();
  };
  return (
    <>
      <Tabs value={depositOrWithdraw} onChange={handleVectorChange}>
        <Tab label="Deposit"></Tab>
        <Tab label="Withdraw"></Tab>
      </Tabs>
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h2"
        sx={{ margin: 2, marginBottom: 4 }}
      >
        {(!depositOrWithdraw && "Replenishing your chest") ||
          "Withdraw from chest"}
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h6"
        sx={{ margin: 2, marginBottom: 4 }}
      >
        Balance: {deposit ? ethers.utils.formatEther(deposit) : "... "}
        <ChainLogo />
      </Typography>
      <Stack direction="column" spacing={2}>
        <TextFieldCustom
          label="ETH amount"
          value={amount}
          onChange={onChangeAmount}
          InputProps={{
            inputProps: {
              min: 0,
              type: "string",
            },
          }}
        />
        {!!depositOrWithdraw && (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setAmount(deposit ? ethers.utils.formatEther(deposit) : "0");
            }}
          >
            Withdraw all
          </Link>
        )}
        <ButtonCustom
          onClick={handleAction}
          variant="contained"
          sx={{ padding: 2 }}
        >
          {depositOrWithdraw === 0 ? "Deposit" : "Withdraw"}
        </ButtonCustom>
      </Stack>
    </>
  );
};
const ExchangeDoubloonComponent: React.FC<
  ModalNS.ChestReplenishComponentProps
> = ({
  amount,
  onChangeAmount,
  handleCloseModal,
  setAmount,
  handleOpenErrorSnackbar,
}) => {
  const { balanceBst, balanceDoubloon } = useChainValues();
  const { burnBst, burnDoubloon } = useChainMethods();
  const [burnOrMint, setDepositOrWithdraw] = useState(0);
  const handleVectorChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setAmount(0);
    setDepositOrWithdraw(newValue);
  };
  const handleAction = () => {
    return burnOrMint === 0
      ? (() => {
          try {
            burnDoubloon(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.DoubloonBurnError);
            console.error(e);
          }
          handleCloseModal();
          setAmount(0);
        })()
      : (() => {
          try {
            burnBst(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.BstBurnError);
            console.error(e);
          }
          handleCloseModal();
          setAmount(0);
        })();
  };
  const doubloonOutput = (() => {
    try {
      return ethers.utils.formatUnits(Number(amount) * 1000, 8);
    } catch (e) {
      console.error(e);
      return 0;
    }
  })();
  const bsOutput = (() => {
    try {
      return ethers.utils.parseUnits(amount, 8).toNumber() / 1000;
    } catch (e) {
      console.error(e);
      return 0;
    }
  })();
  return (
    <>
      <Tabs value={burnOrMint} onChange={handleVectorChange}>
        <Tab label="Buy"></Tab>
        <Tab label="Burn"></Tab>
      </Tabs>
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h2"
        sx={{ margin: 2, marginBottom: 4 }}
      >
        {burnOrMint ? "Burn blackspot" : "Mint blackspot"}
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h6"
        sx={{ margin: 2, marginBottom: 4 }}
      >
        BlackSpot: {balanceBst ?? "... "} BS
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h6"
        sx={{ margin: 2, marginBottom: 4 }}
      >
        Doubloon:{" "}
        {balanceDoubloon
          ? ethers.utils.formatUnits(balanceDoubloon, 8)
          : "... "}
        DBL
      </Typography>
      <Stack direction="column" spacing={2}>
        <TextFieldCustom
          label={burnOrMint ? "Black spot amount" : "Doubloon amount"}
          value={amount}
          onChange={onChangeAmount}
          InputProps={{
            inputProps: {
              min: 0,
              type: "string",
              step: !burnOrMint ? "0.00000001" : "1",
            },
          }}
        />
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            setAmount(
              !burnOrMint
                ? balanceDoubloon
                  ? ethers.utils.formatUnits(balanceDoubloon, 8)
                  : "..."
                : balanceBst
            );
          }}
        >
          Max
        </Link>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h6"
          sx={{ margin: 2, marginBottom: 4 }}
        >
          {burnOrMint
            ? `Doubloon: ${doubloonOutput} DBL`
            : `BlackSpot: ${bsOutput} BS`}
        </Typography>
        <ButtonCustom
          onClick={handleAction}
          variant="contained"
          disabled={!burnOrMint ? bsOutput <= 0 : doubloonOutput < 1}
          sx={{ padding: 2 }}
        >
          {burnOrMint === 0 ? "Buy" : "Burn"}
        </ButtonCustom>
      </Stack>
    </>
  );
};

const Modal: React.FC<ModalNS.Props> = ({
  isModalOpen,
  modalContent,
  handleCloseModal,
  recepientAddress,
  setRecepientAddress,
  handleOpenErrorSnackbar,
  winners,
}) => {
  const { transferBst: transfer } = useChainMethods();

  const [amount, setAmount] = useState<string>("0");

  const onChangeAmount = (event: any) => {
    event.preventDefault();
    setAmount(event.target.value);
  };

  return (
    <MuiModal open={isModalOpen} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#FFF",
          boxShadow: 24,
          p: 4,
          borderRadius: 5,
          width: useMediaQuery("(min-width:768px)")
            ? 400
            : window.screen.width - 100,
        }}
      >
        {modalContent === ModalContentType.ReplenishChest ? (
          <ChestReplenishComponent
            amount={amount}
            onChangeAmount={onChangeAmount}
            handleCloseModal={handleCloseModal}
            setAmount={setAmount}
            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
          />
        ) : null}
        {modalContent === ModalContentType.ExchangeDoubloon ? (
          <ExchangeDoubloonComponent
            amount={amount}
            onChangeAmount={onChangeAmount}
            handleCloseModal={handleCloseModal}
            setAmount={setAmount}
            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
          />
        ) : null}
        {modalContent === ModalContentType.TransferToAnother ? (
          <TransferToAnotherComponent
            amount={amount}
            onChangeAmount={onChangeAmount}
            recepientAddress={recepientAddress}
            transfer={transfer}
            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
            setRecepientAddress={setRecepientAddress}
            handleCloseModal={handleCloseModal}
            setAmount={setAmount}
          />
        ) : null}
        {modalContent === ModalContentType.GameOver ? (
          <GameOver winners={winners} />
        ) : null}
      </Box>
    </MuiModal>
  );
};

export default Modal;

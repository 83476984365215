import styled from "@emotion/styled";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { addHours } from "../../utils/utils";

const TimerContainerEndTime = styled(Button)`
  border-radius: 25px;
  text-transform: none;
  &&& {
    &.Mui-disabled {
      color: #fff;
      background: #167340;
    }
  }
`;

const TimerContainerYellow = styled(Button)`
  border-radius: 25px;
  text-transform: none;
  &&& {
    &.Mui-disabled {
      color: #fff;
      background: #bfa522;
    }
  }
`;

const TimerContainerOrange = styled(Button)`
  border-radius: 25px;
  text-transform: none;
  &&& {
    &.Mui-disabled {
      color: #fff;
      background: #bf4c22;
    }
  }
`;

const TimerContainerRed = styled(Button)`
  border-radius: 25px;
  text-transform: none;
  &&& {
    &.Mui-disabled {
      color: #fff;
      background: #bf2222;
    }
  }
`;

const TimerContainer = styled(Button)`
  border-radius: 25px;
  text-transform: none;
`;

const Timer: React.FC<TimerNS.Props> = ({
  startDate,
  finishTransactions,
  totalTransactions,
}) => {

  const { enqueueSnackbar } = useSnackbar();

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const handleLittleTimeLeftSnackbar = (minutes: number) => {
    enqueueSnackbar(minutes === 0  ? 'Time is over! Before the end of the game 3 transactions' : `Only ${minutes} minutes left before the end of time!`, {
      autoHideDuration: 3000,
      variant: 'warning'
    });
};

  useEffect(() => {
    const endTime = addHours(1 / 6, new Date(startDate)).getTime();

    const minutes = Math.floor((endTime - (new Date() as any)) / 60000);
    console.log(minutes);
    console.log(endTime);
    setMinutes(minutes > 0 ? minutes : 0);
    setSeconds(
      minutes >= 0
        ? Math.floor(((endTime - (new Date() as any)) / 1000) % 60)
        : 0
    );
  }, [startDate]);




  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        // console.log(minutes + ' ' + seconds);
      }
      if (seconds <= 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds((sec) => 59);
          if (minutes < 3){
            handleLittleTimeLeftSnackbar(minutes)
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  if (minutes === 0 && seconds === 0 && finishTransactions === 1) {
    return (
      <TimerContainerRed
        disabled
        variant="contained"
        startIcon={<AccessAlarmIcon />}
      >
        {`moves until the end of the game: ${finishTransactions}`}
      </TimerContainerRed>
    );
  } else if (minutes === 0 && seconds === 0 && finishTransactions === 2) {
    return (
      <TimerContainerOrange
        disabled
        variant="contained"
        startIcon={<AccessAlarmIcon />}
      >
        {`moves until the end of the game: ${finishTransactions}`}
      </TimerContainerOrange>
    );
  } else if (minutes === 0 && seconds === 0 && finishTransactions === 3) {
    return (
      <TimerContainerYellow
        disabled
        variant="contained"
        startIcon={<AccessAlarmIcon />}
      >
        {`moves until the end of the game: ${finishTransactions}`}
      </TimerContainerYellow>
    );
  } else if (minutes === 0 && seconds === 0) {
    return (
      <TimerContainerEndTime
        disabled
        variant="contained"
        startIcon={<AccessAlarmIcon />}
      >
        {"Game over"}
      </TimerContainerEndTime>
    );
  } else {
    return (
      <TimerContainer
        disabled
        sx={{ backgroundColor: "#D9D9D9" }}
        variant="contained"
        startIcon={<AccessAlarmIcon />}
      >
        {minutes + " : " + (seconds < 10 ? `0${seconds}` : seconds)}
      </TimerContainer>
    );
  }
};

export default Timer;

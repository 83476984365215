import { useContext } from "react";
import { Socket } from "socket.io-client";
import { ContractContext } from "../App";

//////////////////////////////////////////////////////
/// ОДУМАТЬСЯ
//////////////////////////////////////////////////////
/*type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type LastOf<T> = UnionToIntersection<
  T extends any ? () => T : never
> extends () => infer R
  ? R
  : never;
type Push<T extends any[], V> = [...T, V];
// TS4.0+
type TuplifyUnion<
  T,
  L = LastOf<T>,
  N = [T] extends [never] ? true : false
> = true extends N ? [] : Push<TuplifyUnion<Exclude<T, L>>, L>;
type ObjValueTuple<
  T,
  KS extends any[] = TuplifyUnion<keyof T>,
  R extends any[] = []
> = KS extends [infer K, ...infer KT]
  ? ObjValueTuple<T, KT, [...R, T[K & keyof T]]>
  : R;
  */
//////////////////////////////////////////////////////

export const _onGameStarted = async (
  socket: Socket,
  callback: (initialGameData: InitialGameData) => any
) => {
  socket.on("GameStarted", (initialGameData: InitialGameData) => {
    callback(initialGameData);
  });
};
export const _onInitialGameData = async (
  socket: Socket,
  callback: (initialGameData: InitialGameData) => any
) => {
  socket.on("InitialGameData", (initialGameData: InitialGameData) => {
    callback(initialGameData);
  });
};
export const _onGameTransaction = (
  socket: Socket,
  callback: (gameTransactionData: GameTransaction) => any
) => {
  socket.on("GameTransaction", (gameTransactionData: GameTransaction) => {
    callback(gameTransactionData);
  });
};
export const _onGameOver = async (
  socket: Socket,
  callback: (gameOverData: GameOverData) => any
) => {
  socket.on("GameOver", (gameOverData: GameOverData) => {
    callback(gameOverData);
  });
};
export const _onWinner = async (
  socket: Socket,
  callback: (winnerData: Winner) => any
) => {
  socket.on("Winner", (winnerData: Winner) => {
    callback(winnerData);
  });
};

export const useSocketListeners = () => {
  const socket = useContext(ContractContext).socket;
  return {
    onGameOver: (callback: (gameOverData: GameOverData) => any) => {
      _onGameOver(socket, callback);
    },
    onGameStarted: (callback: (initialGameData: InitialGameData) => any) => {
      _onGameStarted(socket, callback);
    },
    onInitialGameData: (
      callback: (initialGameData: InitialGameData) => any
    ) => {
      _onInitialGameData(socket, callback);
    },
    onGameTransaction: (
      callback: (transactionData: GameTransaction) => any
    ) => {
      _onGameTransaction(socket, callback);
    },
    onWinner: (callback: (winnerData: Winner) => any) => {
      _onWinner(socket, callback);
    },
  };
};

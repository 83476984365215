import { Contract, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { SnackbarProvider } from "notistack";
import "./App.css";

import axios from "axios";
import Game from "./game/Game";
import { connectMetamask } from "./utils/chain";
import { useSocketListeners } from "./utils/socket";

const socket = io("https://dev.pirate.top"); // TODO: move to ENV

export const ContractContext = React.createContext<{
  blackSpotAddress?: string;
  blackSpot?: Contract;
  doubloonAddress?: string;
  doubloon?: Contract;
  socket: Socket;
  values: ChainValues;
  setValues?: React.Dispatch<React.SetStateAction<ChainValues>>;
}>({
  socket,
  values: {
    prizeChest: "0",
    deposit: "0",
    address: "0x",
    finishTransactions: "0",
    balanceBst: "0",
    balanceDoubloon: "0",
  },
});
const App = () => {
  const [blackSpot, setBlackSpot] = useState<ethers.Contract>();
  const [currentPage, setCurrentPage] = useState("game");
  const [gameResultsData, setGameResultsData] = useState<GameResultData[]>([]);
  const [doubloon, setDoubloon] = useState<ethers.Contract>();
  const [blackSpotAddress, setBlackSpotAddress] = useState<string>();
  const [doubloonAddress, setDoubloonAddress] = useState<string>();
  const { onInitialGameData } = useSocketListeners();
  const [values, setValues] = useState<ChainValues>({
    prizeChest: "0",
    deposit: "0",
    address: "0x",
    finishTransactions: "0",
    balanceBst: "0",
    balanceDoubloon: "0",
  });
  useEffect(() => {
    axios.get("https://dev.pirate.top/api/results").then(
      (response: any) => {
        console.log("get games results");
        setGameResultsData(response.data);
      },
      (error: any) => {
        console.log("no game results");
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    socket.on("connect", () => console.log("Connected socket", socket.id));
  }, [socket]);
  useEffect(() => {
    socket.on("disconnect", (...args) =>
      console.error("Disconnected socket", socket.id, { args })
    );
  }, [socket]);

  useEffect(() => {
    onInitialGameData((initialGD) => {
      console.log({ initialGD });

      setBlackSpotAddress(initialGD.bsContractAddress);
      setDoubloonAddress(initialGD.dbContractAddress);
    });
  }, []);

  useEffect(() => {
    console.log(
      { contractAddress: blackSpotAddress, setContract: setBlackSpot },
      { contractAddress: doubloonAddress, setContract: setDoubloon }
    );

    connectMetamask(
      { contractAddress: blackSpotAddress, setContract: setBlackSpot },
      { contractAddress: doubloonAddress, setContract: setDoubloon }
    ).then((provider) => {
      provider?.getNetwork().then((network) => {
        setValues((values) => ({
          ...values,
          chainId: network.chainId,
        }));
      });
    });
  }, [blackSpotAddress, doubloonAddress]);

  return (
    <div className="App">
      <ContractContext.Provider
        value={{ blackSpot, doubloon, socket, values, setValues }}
      >
        <SnackbarProvider maxSnack={5} preventDuplicate>
          <Game
            blackSpotAddress={blackSpotAddress ?? ""}
            doubloonAddress={doubloonAddress ?? ""}
          />
        </SnackbarProvider>
      </ContractContext.Provider>
    </div>
  );
};

export default App;

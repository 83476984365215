import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useChainValues } from "../../../utils/chain";
import { ModalContentType } from "../../../utils/utils";
import styles from "../../styles.module.scss";
import { BigNumber } from "ethers";

export const BubbleCallout: React.FC<BubbleCalloutNS.Props> = ({
  scale,
  focusedPirate,
  setFocusedPirate,
  handleOpenModal,
  setRecepientAddress,
}) => {
  const scaledStyle = (fontSize: number) => ({
    style: {
      fontSize: fontSize / scale,
    },
  });
  const { address } = useChainValues();
  return (
    (focusedPirate && (
      <foreignObject
        height={200 / scale}
        width={1100 / scale}
        x={focusedPirate.x}
        y={-focusedPirate.y}
        className={styles.callout}
        style={{
          border: `${2 / scale}px solid rgb(84, 110, 110)`,
          borderRadius: `${10 / scale}px`,
        }}
      >
        <div className={styles.calloutContainer} {...scaledStyle(60)}>
          <div
            className={styles.calloutData}
            style={{
              padding: `0 ${50 / scale}px`,
            }}
          >
            <div className={styles.address}>
              <span className={styles.valueTitle}>address</span>:{" "}
              <span className={styles.value}>
                {focusedPirate.address?.slice(0, 4) +
                  "..." +
                  focusedPirate.address?.slice(-6)}
              </span>
            </div>
            <div className={styles.balance}>
              <span className={styles.valueTitle}>balance</span>:{" "}
              <span className={styles.value}>
                {BigNumber.from(focusedPirate.amount).toNumber()}
              </span>
            </div>
          </div>
          <div className={styles.closeButtonContainer}>
            <MailOutlineIcon
              onClick={() => {
                setRecepientAddress(focusedPirate.address);
                handleOpenModal(
                  ModalContentType.TransferToAnother,
                  address ?? "..."
                );
                setFocusedPirate(undefined);
              }}
              className={styles.closeButton}
              {...scaledStyle(100)}
            />
            <CloseIcon
              onClick={() => setFocusedPirate(undefined)}
              className={styles.closeButton}
              {...scaledStyle(100)}
            />
          </div>
        </div>
      </foreignObject>
    )) || <></>
  );
};

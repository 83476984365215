import { SelectChangeEvent, Stack } from "@mui/material";
import { useState } from "react";
import { sortGames } from "../../utils/utils";
import GameCards from "./components/GameCards";
import { PeriodButtons } from "./components/PeriodButtons";

const Leaderboard: React.FC<LeaderboardNS.Props> = ({
  gameResultData,
  avaliableDaysPeriods,
}) => {
  const [selectedPeriod, setPeriod] = useState(0);
  const [sortingType, setSortingType] = useState("");

  const handleChangePeriod = (e: any) => {
    setPeriod(e.target.dataset.id);
  };

  const handleChangeSortingType = (event: SelectChangeEvent) => {
    setSortingType(event.target.value as string);
  };

  return (
    <Stack
      sx={{
        backgroundColor: "#FFF",
        height: "85%",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <PeriodButtons
        selectedPeriod={selectedPeriod}
        handleChangePeriod={handleChangePeriod}
        avaliableDaysPeriods={avaliableDaysPeriods}
        sortingType={sortingType}
        handleChangeSortingType={handleChangeSortingType}
      />
      <GameCards
        leaderboard={sortGames(gameResultData, selectedPeriod, sortingType)}
      />
    </Stack>
  );
};

export default Leaderboard;

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { ErrorType, getCookie, MetamaskError, TokenType } from '../../utils/utils';
import { ButtonCustom } from '../buttonCustom/ButtonCustom';



const CustomSnackbar: React.FC<CustomSnackbarNS.Props> = ({ isOpen, handleOpenErrorSnackbar, handleClose, handleAnywayClose, bsAddress, dAddress }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackPack, setSnackPack] = React.useState<readonly CustomSnackbarNS.SnackbarMessage[]>([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<CustomSnackbarNS.SnackbarMessage | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev: readonly CustomSnackbarNS.SnackbarMessage[]) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);


  const addToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number,
    tokenImageSource: string, type: string, tokenType: TokenType) => {
    try {
      //@ts-ignore
      const wasAdded = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: type,
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImageSource,
          },
        },
      });
      if (wasAdded) {
        console.log('Thanks for adding Black Spot Pirates interest!');
        const nextWeek = new Date();
        nextWeek.setDate(new Date().getDate() + 7);
        const cookieName = tokenType === TokenType.BlackSpotToken ? 'bsTokenAdded' : 'dTokenAdded';
        document.cookie = `${cookieName}=true; expires=${nextWeek}`;
        handleAnywayClose();
      } else {
        console.log('BST has not been added');
        enqueueSnackbar('An error occurred while importing the token', {
          autoHideDuration: 3000,
          variant: 'error'
        });
        handleAnywayClose();
        handleOpenErrorSnackbar(ErrorType.ErrorImportingToken);
      }
    } catch (error: unknown) {
      handleAnywayClose();
      console.error(error);

      if (typeof window.ethereum === 'undefined') {
        handleOpenErrorSnackbar(ErrorType.ErrorNoMetamaskInstalled);
      } else if ((error as MetamaskError).code === -32603) {
        handleOpenErrorSnackbar(ErrorType.ForbiddenLocalChainId);
      } else if ((error as MetamaskError).code === 4001) {
        handleOpenErrorSnackbar(ErrorType.RejectedAddingToken);
      } else {
        handleOpenErrorSnackbar(ErrorType.UnknownError);
      }

    }
  };

  const handleImportContract = () => {
    if (!Boolean(getCookie('bsTokenAdded'))){
      addToken(bsAddress, 'BS', 0, 'https://cdn4.vectorstock.com/i/thumb-large/67/93/skull-crossbones-black-casino-chip-vector-19436793.jpg', 'ERC20', TokenType.BlackSpotToken);
    }
    if (!Boolean(getCookie('dTokenAdded'))){
      addToken(dAddress, 'DB', 18, 'https://cdn1.iconfinder.com/data/icons/pirates-12/64/11-pirate_gold_coins-bandit-pirate-gold-currency-skull-512.png', 'ERC20', TokenType.DoubloonToken);
    }
  }


  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        autoHideDuration={30000}
        onClose={handleClose}
        message={'You may need to add the token to the Metamask.'}
        action={
          <React.Fragment>
            <ButtonCustom variant="contained" onClick={handleImportContract}>
              Import tokens
            </ButtonCustom>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}


export default CustomSnackbar;
import { Clear } from '@mui/icons-material';
import {
  Box,
  ButtonGroup,
  IconButton,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { addHours, isInThePast } from '../../utils/utils';
import { ButtonCustom } from '../buttonCustom/ButtonCustom';
import Timer from '../timer/Timer';

export enum Device {
  Desktop,
  Tablet,
  Smartphone,
}

export const TextFieldCustom = styled(TextField)`
  fieldset {
    border-radius: 25px;
  }
`;

const GameStatus: React.FC<GameStatusNS.Props> = ({
  startDate,
  totalTransactions,
  finishTransactions,
}) => {
  let device: Device = Device.Smartphone;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:768px)');

  const handleFinishTransactionsSnackbar = (finishTransactions: number) => {
    enqueueSnackbar(
      finishTransactions === 0 ? (
        <>
          The last transaction in this game has just been completed{' '}
          <IconButton
            aria-label='cancel'
            sx={{ color: 'white' }}
            onClick={() => closeSnackbar()}
          >
            <Clear />
          </IconButton>
        </>
      ) : (
        <>
          There are {finishTransactions} transactions left until the end of the
          game{' '}
          <IconButton
            aria-label='cancel'
            sx={{ color: 'white' }}
            onClick={() => closeSnackbar()}
          >
            <Clear />
          </IconButton>
        </>
      ),
      {
        autoHideDuration: 2300,
        variant: 'warning',
      }
    );
  };

  if (isDesktop) {
    device = Device.Desktop;
  } else if (isTablet) {
    device = Device.Tablet;
  } else {
    device = Device.Smartphone;
  }

  useEffect(() => {
    console.log(typeof finishTransactions);
    const endTime = addHours(1 / 6, new Date(startDate));

    if (isInThePast(endTime))
      handleFinishTransactionsSnackbar(finishTransactions);
  }, [finishTransactions]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 91,
        left: '50%',
        transform: 'translate(-50%, 0)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction={device === Device.Desktop ? 'row' : 'column'}
          sx={
            device === Device.Desktop
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              : {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
          }
        >
          <Box
            sx={
              device === Device.Desktop
                ? {
                    width: 'fit-content',
                    borderRadius: '20px',
                  }
                : {
                    width: 'fit-content',
                    borderRadius: '20px',
                  }
            }
          >
            <Stack
              direction='row'
              flexWrap='wrap'
              rowGap={2}
              height='max-content'
              justifyContent='start'
            >
              <ButtonGroup>
                <Timer
                  startDate={startDate}
                  finishTransactions={finishTransactions}
                  totalTransactions={totalTransactions}
                />
                <ButtonCustom
                  sx={{ backgroundColor: 'white' }}
                  disabled
                >{`Total: ${totalTransactions} TXNs`}</ButtonCustom>
              </ButtonGroup>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default GameStatus;

import { Box, Stack, Typography } from "@mui/material";
import dateFormat from "dateformat";
import { BigNumber, ethers } from "ethers";
import depositChestImage from "../../../assets/images/DepositChest.png";
import prizeChestImage from "../../../assets/images/PrizeChest.png";

import { ChainLogo } from "../../chainLogo/ChainLogo";

const GameCard = ({ endgame, winners, startgame, amount }: GameResultData) => {
  const ChestImg = () => (
    <>
      {endgame && winners ? (
        <img
          style={{
            width: 40,
            height: 40,
          }}
          src={prizeChestImage}
          alt="prizeChestImage"
        />
      ) : (
        <img
          style={{
            width: 40,
            height: 40,
          }}
          src={depositChestImage}
          alt="depositChestImage"
        />
      )}
    </>
  );

  return (
    <Stack
      style={{
        overflowWrap: "anywhere",
        display: "flex",
        padding: "10px",
        gap: "10px",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#D9D9D9",
        borderRadius: 25,
      }}
    >
      <ChestImg />
      <Stack direction="column" sx={{ width: "100%", height: "100%" }}>
        <Stack direction="row" sx={{ maxWidth: "100%", flexWrap: "wrap" }}>
          Game start time
          <span>
            {dateFormat(
              new Date(BigNumber.from(startgame).toNumber() * 1000),
              "ddd, mmm dS, yyyy, HH:MM"
            )}
          </span>
        </Stack>
        {endgame ? (
          <Stack direction="row" sx={{ flexWrap: "wrap", gap: "5px" }}>
            Game end time
            <span style={{ width: "100%" }}>
              {dateFormat(
                new Date(BigNumber.from(endgame).toNumber() * 1000),
                "ddd, mmm dS, yyyy, HH:MM"
              )}
            </span>
          </Stack>
        ) : (
          <Typography>{`This game is not over yet`}</Typography>
        )}
        <Stack direction="row" sx={{ flexWrap: "wrap", gap: "2px" }}>
          {`Winning amount ${ethers.utils.formatEther(amount)}`}
          <div style={{ display: "inline-block" }}>
            <ChainLogo />
          </div>
        </Stack>
        {winners ? (
          winners.length > 0 ? (
            <Stack direction="column" sx={{ flexWrap: "wrap", gap: "5px", marginTop: "10px" }}>
              <Typography sx={{ display: "block"}}>{winners.length > 1 ? 'Winners:' : 'Winner:'}</Typography>
              <>
                {
                winners.map((value: Pirate) => (<Typography>{`${value.address}`}</Typography>))
                }
              </>
            </Stack>
          ) : null
        ) : endgame ? (
          <Typography>{`The game ended in a draw`}</Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

const GameCards: React.FC<LeaderboardNS.Profiles> = ({ leaderboard }) => {
  if (leaderboard.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        whiteSpace="break-spaces"
        alignItems="center"
        minHeight="20vh"
      >
        <Typography
          sx={{ fontSize: 24 }}
        >{`There were no games during this period`}</Typography>
      </Box>
    );
  } else {
    return (
      <Box
        id="profile"
        sx={{
          overflowY: "auto",
          gap: "15px",
          display: "flex",
          height: "100%",
          flexDirection: "column",
        }}
      >
        {leaderboard.map(GameCard)}
      </Box>
    );
  }
};

export default GameCards;

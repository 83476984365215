import React, { useContext } from "react";
import { ContractContext } from "../../App";
import { ReactComponent as EthLogo } from "../../assets/svgs/eth.svg";
import { ReactComponent as BscLogo } from "../../assets/svgs/bsc.svg";
import { ReactComponent as AvaxLogo } from "../../assets/svgs/avax.svg";
import { ReactComponent as PolygonLogo } from "../../assets/svgs/polygon.svg";
import { ReactComponent as UndefinedLogo } from "../../assets/svgs/undefined.svg";

export const ChainLogo = () => {
  let chainId = useContext(ContractContext).values.chainId;
  const Logo: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > = () => {
    switch (chainId) {
      case 1:
        return <EthLogo width="100%" height="100%" />;
      case 56:
        return <BscLogo width="100%" height="100%" />;
      case 43114:
        return <AvaxLogo width="100%" height="100%" />;
      case 137:
        return <PolygonLogo width="100%" height="100%" />;
      default:
        return <UndefinedLogo width="100%" height="100%" />;
    }
  };
  return (
    <div style={{ width: "20px", height: "20px" }}>
      <Logo style={{ width: "100%", height: "100%" }} />
    </div>
  );
};
